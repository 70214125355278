<template>
  <div>
    <div class="text-right mb-2">
      <b-button
        variant="primary"
        @click="showLocationModal()"
      >
        Add Location
      </b-button>
    </div>
    <b-card no-body>
      <!--table top-->
      <div class="m-2">
        <b-row class="justify-content-end">
          <!--search-->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center">
              <b-form-input
                v-model="searchQuery"
                class="mr-1"
                placeholder="Search..."
                @input="debounceSearch(meta.offset= 0, getLocationsList)"
              />
            </div>
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <v-select
                v-model="region"
                :options="[{value: 'BD', label: 'Bangladesh'}, {value: 'PK', label: 'Pakistan'}]"
                :reduce="option => option.value"
                :clearable="true"
                class="status-filter-select"
                placeholder="Select Region"
                @input="getLocationsList()"
              >
                <template #selected-option="{ label }">
                  <span class="text-truncate overflow-hidden">
                    {{ label }}
                  </span>
                </template>
              </v-select>
            </div>
          </b-col>
        </b-row>
      </div>
      <b-table
        id="tourTokenizer"
        :items="locationList"
        :fields="tableColumns"
        :busy="isTableLoading"
        :per-page="meta.limit"
        :current-page="currentPage"
        primary-key="id"
        empty-text="No matching records found"
        responsive
        show-empty
        class="position-relative"
      >
        <template #table-busy>
          <div class="text-center text-primary m-2">
            <b-spinner class="align-middle" />
            <strong> Loading...</strong>
          </div>
        </template>
        <template #cell(three_dot)="data">
          <b-dropdown
            variant="link"
            toggle-class="p-0"
            class="action-menu"
            no-caret
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item @click="showLocationModal(data.item)">
              <feather-icon icon="Edit2Icon" />
              <span
                class="align-middle ml-50"
              >
                Edit
              </span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
        <template #cell(city)="data">
          <div class="text-nowrap">
            {{ data.item.city ? data.item.city.name : 'N/A' }}
          </div>
        </template>
      </b-table>
      <b-row
        v-if="locationList.length > 0"
        class="mx-1 mb-2"
      >
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">
            Showing {{ (currentPage - 1) * 10 + 1 }} to {{ (meta.limit) * (currentPage) }} of {{ meta.total }} entries
          </span>
        </b-col>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="meta.total"
            :per-page="meta.limit"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
            @input="paginate(currentPage)"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
      <LocationModal
        :location-details="locationDetails"
        @updateTourlocation="getLocationsList"
      />
    </b-card>
  </div>
</template>

<script>
import { required } from '@validations'
import api from '@/libs/api'
import LocationModal from '@/views/locations/LocationModal.vue'
import helper from '@/mixins/helper'

export default {
  name: 'LocationList',
  components: {
    LocationModal,
  },
  mixins: [helper],
  data() {
    return {
      required,
      locationList: [],
      isTableLoading: false,
      currentPage: 1,
      region: '',
      meta: {
        offset: 0,
        limit: 10,
        total: 0,
      },
      regionFormat: { BD: 'Bangladesh', PK: 'Pakistan' },
      tableColumns: [
        { key: 'three_dot', label: '' },
        { key: 'name', thClass: 'md' },
        { key: 'alias', thClass: 'md', formatter: value => value || 'N/A' },
        { key: 'region', formatter: value => this.regionFormat[value] },
        { key: 'city', formatter: value => value || 'N/A' },
        { key: 'latitude', formatter: value => value || 0 },
        { key: 'longitude', formatter: value => value || 0 },
      ],
      requestType: '',
      locationDetails: {},
      searchQuery: '',
    }
  },
  computed: {
    filterQuery() {
      const search = this.searchQuery ? `search=${this.searchQuery}` : ''
      const region = this.region ? `&region=${this.region}` : ''
      return `${search}${region}`
    },
  },
  mounted() {
    this.getLocationsList()
  },
  methods: {
    getLocationsList() {
      this.isTableLoading = true
      api.getData(`/location/admin_services/locations/?${this.filterQuery}`, true)
        .then(response => {
          if (response.data.status) {
            this.locationList = response.data.result
            this.meta.total = response.data.result.length
          } else {
            this.notificationMessage('danger', 'XIcon', 'Error', response.data.error.message)
          }
        }).catch(() => {
          this.notificationMessage('danger', 'XIcon', 'Error', 'Sorry, something went wrong')
        }).finally(() => {
          this.isTableLoading = false
        })
    },
    paginate(page) {
      this.currentPage = page
    },
    showLocationModal(data) {
      if (data) {
        this.locationDetails = data
      } else {
        this.locationDetails = {}
      }
      this.$root.$emit('bv::show::modal', 'location-modal')
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/style.scss';
</style>

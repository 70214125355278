<template>
  <div>
    <b-input-group class="mb-1">
      <vue-autosuggest
        v-model="locationQuery"
        :input-props="{id:'autosuggest__input',class:'form-control w-100', placeholder: 'Search your location'}"
        :suggestions="[{ data: locationSuggestionList }]"
        :limit="10"
        class="w-100"
        @input="debounceSearch(0, getStreetInfo)"
        @focus="debounceSearch(0, getStreetInfo)"
        @selected="getSelectedAddress"
      >
        <template slot-scope="{suggestion}">
          <span class="my-suggestion-item">{{ suggestion.item.address }}</span>
        </template>
      </vue-autosuggest>
    </b-input-group>
    <div
      id="map"
      class="border"
    />
  </div>
</template>

<script>
/* eslint-disable no-undef */
import { VueAutosuggest } from 'vue-autosuggest'
import tour from '@/mixins/tour'
import helper from '@/mixins/helper'

export default {
  name: 'Mapbox',
  components: {
    VueAutosuggest,
  },
  mixins: [tour, helper],
  props: {
    mapLatitude: {
      type: Number,
      default: 23.8001,
    },
    mapLongitude: {
      type: Number,
      default: 90.4065,
    },
  },
  data() {
    return {
      locationQuery: '',
      locationSuggestionList: [],
      map: {},
      marker: {},
      geocoder: {},
      center: [this.mapLongitude, this.mapLatitude],
      showFullAddress: false,
    }
  },
  watch: {
    mapLongitude() {
      this.showFullAddress = false
      this.setMarkerPosition(this.mapLatitude, this.mapLongitude, false)
    },
  },
  mounted() {
    this.getMap()
  },
  methods: {
    async getMap() {
      try {
        bkoigl.accessToken = 'MjQ4MDpUVk9URU9DTDNM'
        this.map = new bkoigl.Map({
          container: 'map',
          center: [90.3938010872331, 23.821600277500405],
          zoom: 12,
        })
        const nav = new bkoigl.NavigationControl()
        this.map.on('load', () => {
          this.map.addControl(nav, 'bottom-right')
          const geoLocate = new bkoigl.GeolocateControl()
          this.map.addControl(geoLocate)
          geoLocate.on('geolocate', e => {
            const { longitude } = e.coords
            const { latitude } = e.coords
            this.showFullAddress = false
            this.setMarkerPosition(latitude, longitude, true)
          })
        })
        this.marker = new bkoigl.Marker({
          draggable: true,
          color: '#7367f0',
        }).setLngLat(this.center).addTo(this.map)
        const onDragEnd = () => {
          const lngLat = this.marker.getLngLat()
          const latitude = lngLat.lat
          const longitude = lngLat.lng
          this.showFullAddress = false
          this.setMarkerPosition(latitude, longitude, true)
        }
        this.marker.on('dragend', onDragEnd)
      } catch (err) {
        this.notificationMessage('danger', 'XIcon', 'Error', 'Sorry, something went wrong!')
      }
    },
    getAddressFromCoordinates(latitude, longitude) {
      this.getReverseGeocoding(latitude, longitude).then(response => {
        if (response.data.status) {
          const address = response.data.result.place
          if (this.showFullAddress) {
            this.locationQuery = `${address.address}, ${address.area}, ${address.city} - ${address.postCode}`
          }
          this.$emit('getAddress', address)
        } else {
          this.notificationMessage('danger', 'XIcon', 'Error', response.date.error.message)
        }
      }).catch(() => {
        this.notificationMessage('danger', 'XIcon', 'Error', 'Sorry, something went wrong!')
      }).finally(() => {
        this.showFullAddress = false
      })
    },
    getSelectedAddress(data) {
      const { latitude } = data.item
      const { longitude } = data.item
      this.showFullAddress = false
      this.locationQuery = `${data.item.address || ''} ${data.item.city || ''} `
      this.setMarkerPosition(latitude, longitude, false)
      this.$emit('getCoordinates', latitude, longitude)
      this.$emit('getAddress', data.item)
    },
    getStreetInfo() {
      if (this.locationQuery) {
        this.getAreaSuggestionFromBK(this.locationQuery).then(response => {
          if (response.data.status) {
            this.locationSuggestionList = response.data.result.places
          }
        }).catch(() => {
          this.notificationMessage('danger', 'XIcon', 'Error', 'Sorry, something went wrong!')
        })
      }
    },
    setMarkerPosition(latitude, longitude, fetchAddress) {
      this.marker.setLngLat([longitude, latitude]).addTo(this.map)
      this.map.panTo([longitude, latitude])
      if (fetchAddress) {
        this.$emit('getCoordinates', latitude, longitude)
        this.getAddressFromCoordinates(latitude, longitude)
      }
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/tour.scss';
@import '@core/scss/vue/libs/vue-autosuggest.scss';
</style>
<style lang="scss" scoped>
#map {
  width: 100%;
  min-height: 500px;
  border-radius: 5px;
}
</style>

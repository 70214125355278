<template>
  <b-modal
    id="location-modal"
    ref="location-modal"
    centered
    hide-footer
    size="lg"
    modal-class="modal-primary"
    :title="isObjectEmpty(locationDetails) ? 'Edit Location': 'Add Location'"
    @hidden="clear"
    @shown="getLocationData"
  >
    <validation-observer ref="tour-location-form">
      <b-row
        v-if="locationDetails"
        class="mb-1"
      >
        <b-col md="6">
          <b-row>
            <b-col md="12">
              <b-form-group
                label="Name"
                label-for="name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="name"
                  rules="required"
                >
                  <b-form-input
                    id="longitude"
                    v-model="tourLocation.name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="Alias"
                label-for="alias"
              >
                <b-form-input
                  id="alias"
                  v-model="tourLocation.alias"
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="Region"
                label-for="region"
              >
                <validation-provider
                  #default="{ errors }"
                  name="region"
                >
                  <v-select
                    id="region"
                    v-model="tourLocation.region"
                    :options="countries"
                    :reduce="option => option.value"
                    label="name"
                    class="w-100"
                    @input="getDistrictList"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="City"
                label-for="city"
              >
                <validation-provider
                  #default="{ errors }"
                  name="city"
                >
                  <v-select
                    id="city"
                    v-model="tourLocation.city"
                    :options="districtList"
                    :reduce="district => district.id"
                    label="name"
                    class="w-100"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Latitude"
                label-for="latitude"
              >
                <validation-provider
                  #default="{ errors }"
                  name="latitude"
                >
                  <b-form-input
                    id="latitude"
                    v-model="tourLocation.latitude"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Longitude"
                label-for="longitude"
              >
                <validation-provider
                  #default="{ errors }"
                  name="latitude"
                >
                  <b-form-input
                    id="longitude"
                    v-model="tourLocation.longitude"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="Type"
                label-for="type"
              >
                <validation-provider
                  #default="{ errors }"
                  name="type"
                >
                  <v-select
                    id="type"
                    v-model="tourLocation.type"
                    :options="['Country', 'City' ,'State', 'Province']"
                    class="w-100"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
        <b-col
          md="6"
          class="mt-2"
        >
          <Mapbox
            :map-latitude="mapInitialPosition[1]"
            :map-longitude="mapInitialPosition[0]"
            @getCoordinates="getCoordinatesFromMap"
            @getAddress="getAddressFromMap"
          />
        </b-col>
      </b-row>
      <div class="btn-wrapper text-right">
        <b-button
          id="tour-location-btn"
          variant="primary"
          :disabled="isLoading"
          @click="isTourLocationFormValid()"
        >
          <b-spinner
            v-if="isLoading"
            small
          />
          <span>
            {{ isObjectEmpty(locationDetails) ? 'Update': 'Create' }}
          </span>
        </b-button>
      </div>
    </validation-observer>
  </b-modal>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import Mapbox from '@/components/tour/Mapbox.vue'
import api from '@/libs/api'
import helper from '@/mixins/helper'

export default {
  name: 'LocationModal',
  components: {
    Mapbox,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [helper],
  props: {
    locationDetails: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      required,
      isLoading: false,
      districtList: [],
      countries: [
        {
          name: 'Bangladesh',
          value: 'BD',
        },
        {
          name: 'Pakistan',
          value: 'PK',
        },
      ],
      tourLocation: {
        name: '',
        city: '',
        region: 'BD',
        latitude: '',
        longitude: '',
        type: null,
        alias: '',
      },
      mapInitialPosition: [90.3938010872331, 23.821600277500405],
    }
  },
  methods: {
    getDistrictList(city) {
      api.getData(`/location/admin_services/locations/?region=${this.tourLocation.region}&type=City`, true)
        .then(response => {
          if (response.data.status) {
            this.districtList = response.data.result
            if (city) {
              const districtName = this.districtList.filter(district => district.district === city)[0]
              this.tourLocation.city = districtName ? districtName.id : null
            }
          }
        }).catch()
    },
    getCoordinatesFromMap(latitude, longitude) {
      this.tourLocation.latitude = latitude
      this.tourLocation.longitude = longitude
    },
    getLocationData() {
      this.tourLocation = this.isObjectEmpty(this.locationDetails) !== 0 ? JSON.parse(JSON.stringify(this.locationDetails)) : {
        name: '',
        city: '',
        region: 'BD',
        latitude: 23.821600277500405,
        longitude: 90.3938010872331,
        type: null,
        alias: '',
      }
      this.mapInitialPosition = [Number(this.locationDetails.longitude) ? Number(this.locationDetails.longitude) : 90.3938010872331,
        Number(this.locationDetails.latitude) ? Number(this.locationDetails.latitude) : 23.821600277500405]
      this.getDistrictList()
    },
    getAddressFromMap(address) {
      if (address) {
        const previousCountryCode = this.tourLocation.region
        this.tourLocation.region = address.country_code ? address.country_code : this.tourLocation.region
        if (this.tourLocation.region && this.tourLocation.region !== previousCountryCode) {
          this.getDistrictList(address.city)
        } else {
          const districtName = this.districtList.filter(district => district.name && (district.name).toLowerCase() === address.city && (address.city).toLowerCase())[0]
          this.tourLocation.city = districtName ? districtName.id : null
        }
      }
    },
    isTourLocationFormValid() {
      this.$refs['tour-location-form'].validate().then(success => {
        if (success) {
          this.addOrEditLocation()
        } else {
          this.notificationMessage('warning', 'XIcon', 'Error', 'Please fill up all required fields')
        }
      })
    },
    addOrEditLocation() {
      this.isLoading = true
      let url = 'location/admin_services/locations/'
      let apiResponse
      const keys = ['country', 'city', 'state']
      if (this.locationDetails.id) {
        keys.forEach(key => {
          this.tourLocation[key] = this.mapObjId(this.tourLocation, key)
        })
        url = `/location/admin_services/locations/${this.locationDetails.id}/`
        apiResponse = api.updateData(url, true, this.tourLocation)
      } else {
        apiResponse = api.postData(url, true, this.tourLocation)
      }
      apiResponse.then(response => {
        if (response.data.status) {
          this.notificationMessage('success', 'CheckIcon', 'Success', 'Your request is successfully')
          this.$emit('updateTourlocation')
          this.$refs['location-modal'].hide()
        } else {
          this.notificationMessage('danger', 'Xicon', 'Error', JSON.stringify(response.data.error.message))
        }
      }).catch(() => {
        this.notificationMessage('danger', 'XIcon', 'Error', 'Sorry, something went wrong!')
      }).finally(() => {
        this.isLoading = false
      })
    },
    mapObjId(obj, key) {
      return obj[key] ? obj[key].id : obj[key]
    },
    clear() {
      this.tourLocation = {
        name: '',
        city: '',
        region: 'BD',
        latitude: '',
        longitude: '',
        type: null,
        alias: '',
      }
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep #map {
  min-height: 300px;
}
</style>

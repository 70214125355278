var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:"location-modal",attrs:{"id":"location-modal","centered":"","hide-footer":"","size":"lg","modal-class":"modal-primary","title":_vm.isObjectEmpty(_vm.locationDetails) ? 'Edit Location': 'Add Location'},on:{"hidden":_vm.clear,"shown":_vm.getLocationData}},[_c('validation-observer',{ref:"tour-location-form"},[(_vm.locationDetails)?_c('b-row',{staticClass:"mb-1"},[_c('b-col',{attrs:{"md":"6"}},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Name","label-for":"name"}},[_c('validation-provider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"longitude"},model:{value:(_vm.tourLocation.name),callback:function ($$v) {_vm.$set(_vm.tourLocation, "name", $$v)},expression:"tourLocation.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2507862275)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Alias","label-for":"alias"}},[_c('b-form-input',{attrs:{"id":"alias"},model:{value:(_vm.tourLocation.alias),callback:function ($$v) {_vm.$set(_vm.tourLocation, "alias", $$v)},expression:"tourLocation.alias"}})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Region","label-for":"region"}},[_c('validation-provider',{attrs:{"name":"region"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"w-100",attrs:{"id":"region","options":_vm.countries,"reduce":function (option) { return option.value; },"label":"name"},on:{"input":_vm.getDistrictList},model:{value:(_vm.tourLocation.region),callback:function ($$v) {_vm.$set(_vm.tourLocation, "region", $$v)},expression:"tourLocation.region"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3621876861)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"City","label-for":"city"}},[_c('validation-provider',{attrs:{"name":"city"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"w-100",attrs:{"id":"city","options":_vm.districtList,"reduce":function (district) { return district.id; },"label":"name"},model:{value:(_vm.tourLocation.city),callback:function ($$v) {_vm.$set(_vm.tourLocation, "city", $$v)},expression:"tourLocation.city"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,882070654)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Latitude","label-for":"latitude"}},[_c('validation-provider',{attrs:{"name":"latitude"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"latitude"},model:{value:(_vm.tourLocation.latitude),callback:function ($$v) {_vm.$set(_vm.tourLocation, "latitude", $$v)},expression:"tourLocation.latitude"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2150350567)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Longitude","label-for":"longitude"}},[_c('validation-provider',{attrs:{"name":"latitude"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"longitude"},model:{value:(_vm.tourLocation.longitude),callback:function ($$v) {_vm.$set(_vm.tourLocation, "longitude", $$v)},expression:"tourLocation.longitude"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,132237223)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Type","label-for":"type"}},[_c('validation-provider',{attrs:{"name":"type"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"w-100",attrs:{"id":"type","options":['Country', 'City' ,'State', 'Province']},model:{value:(_vm.tourLocation.type),callback:function ($$v) {_vm.$set(_vm.tourLocation, "type", $$v)},expression:"tourLocation.type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,490336211)})],1)],1)],1)],1),_c('b-col',{staticClass:"mt-2",attrs:{"md":"6"}},[_c('Mapbox',{attrs:{"map-latitude":_vm.mapInitialPosition[1],"map-longitude":_vm.mapInitialPosition[0]},on:{"getCoordinates":_vm.getCoordinatesFromMap,"getAddress":_vm.getAddressFromMap}})],1)],1):_vm._e(),_c('div',{staticClass:"btn-wrapper text-right"},[_c('b-button',{attrs:{"id":"tour-location-btn","variant":"primary","disabled":_vm.isLoading},on:{"click":function($event){return _vm.isTourLocationFormValid()}}},[(_vm.isLoading)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),_c('span',[_vm._v(" "+_vm._s(_vm.isObjectEmpty(_vm.locationDetails) ? 'Update': 'Create')+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }